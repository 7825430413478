import React, { useState, useEffect, useContext, memo } from "react";
import lora from "lora-packet";
import { TableContainer, TableBody, TableHead, TableRow, TableCell, FormControl, CardMedia, Tooltip, TableFooter, Button } from '@mui/material';
import { CardHeader, CardContent, Box, Tabs, Tab, CircularProgress, MenuItem, IconButton } from '@mui/material';
import { CustomCard, CustomSelect, CustomTable } from "../common/StyledComponents";
import NotificationContext from "../../context/NotificationContext";
import CodeBox from "../common/CodeBox";
import RefreshIcon from "../../images/refresh.png";
import { handleLoraMessage, formatLoraMessage } from "../../loraDecrypt";

const Uplink = memo(({ packets, sensorDecoder, device, updateKeys, updatePackets, isAllLoaded }) => {

    const notificationCtx = useContext(NotificationContext)
    const [messages, setMessages] = useState(packets);
    const [unique, setUnique] = useState(true);
    const [activeUpId, setActiveUpId] = useState(1);
    const [selectedPacket, setSelectedPacket] = useState("");
    const [appMessage, setAppMessage] = useState(null);
    const [networkMessage, setNetworkMessage] = useState(null);
    const [loraMessage, setLoraMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMessages(packets);
    }, [packets])

    useEffect(() => {
        setUnique(true);
        setSelectedPacket("");
    }, [device.id])

    useEffect(() => {
        if (unique && packets) {
            let uniquePackets = packets.filter((p, i) =>
                packets.findIndex((pp) => p.fcntUp === pp.fcntUp && p.messageType === pp.messageType) === i);
            setMessages(uniquePackets);
        } else {
            setMessages(packets);
        }
    }, [unique, packets])

    useEffect(() => {
        if (selectedPacket !== "") {
            decodePacket()
            setNetworkMessage(JSON.stringify(selectedPacket, undefined, 4))
        }
        else {
            setNetworkMessage(null)
            setLoraMessage(null);
            setAppMessage(null);
        }
    }, [selectedPacket, sensorDecoder])

    const uplinks = [
        { id: 1, text: "App" },
        { id: 2, text: "Network Server" },
        { id: 3, text: "LoRa MAC" },
    ];

    const handleTabChange = (event, newValue) => setActiveUpId(newValue);

    async function decodePacket() {
        try {
            let input = selectedPacket.rawPayload;
            let fcntMSB32 = new Buffer(2);
            let selectedPacketFCnt = selectedPacket.messageType === "Uplink" ? selectedPacket.fcntUp : selectedPacket.fcntDown;
            if (selectedPacketFCnt > 65536) {
                let msb = (selectedPacketFCnt >> 16) & 0xFF;
                fcntMSB32.writeUInt16LE(msb, 0);
            }
            let payloadData = lora.fromWire(Buffer.from(input, 'base64'))
            if (payloadData.getMType() === "Join Accept") {
                let appKey = Buffer.from(device.appKey, "hex");
                payloadData = lora.fromWire(lora.decryptJoinAccept(payloadData, appKey));
            }

            let decodeFileName = sensorDecoder ? sensorDecoder : "/_default-decoder"
            let decodeFile = require("../../sensordecoders" + decodeFileName)
            let networkKey = Buffer.from(device.nwkSKey, "hex")
            let applicationKey = Buffer.from(device.appSKey, "hex")

            let decoded;
            if (applicationKey || networkKey) {
                decoded = handleLoraMessage(payloadData, networkKey, applicationKey, fcntMSB32);
            }
            else {
                decoded = payloadData.toString();
            }
            setLoraMessage(formatLoraMessage(decoded));

            if (payloadData.FRMPayload) {
                if (Number.isFinite(payloadData.FPort[0])) {
                    let decrypted = lora.decrypt(payloadData, applicationKey, networkKey, fcntMSB32)
                    let inputDC = {
                        bytes: decrypted,
                        fPort: payloadData.FPort[0]
                    }
                    setAppMessage(JSON.stringify(decodeFile.decodeUplink(inputDC), undefined, 4))
                } else setAppMessage("Not a valid packet.")
            } else setAppMessage("This packet doesn't have FRMPayload to decode.")
        } catch (e) {
            console.log(e);
            setAppMessage(null)
            notificationCtx.error(e.message);
        }
    }

    const loadMore = () => {
        setLoading(true)
        updatePackets(device.id, () => setLoading(false));
    }

    return (
        <>
            {messages
                ? <Box className="d-flex">
                    <CustomCard sx={{ width: '52%' }}>
                        <CardHeader title="Packet Decoder" />
                        <CardContent>
                            <TableContainer sx={{ height: "100%", overflowY: "auto" }}>
                                <CustomTable stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Time</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Port</TableCell>
                                            <TableCell>
                                                <FormControl variant="standard" size="small" >
                                                    <CustomSelect sx={{ fontSize: "1em", marginBottom: "-5px", "& .MuiSelect-standard": { paddingLeft: "5px" } }}
                                                        value="" displayEmpty
                                                        onChange={e => setUnique(Boolean(e.target.value))}>
                                                        <MenuItem value={""} style={{ display: "none" }}>Fcnt</MenuItem>
                                                        <MenuItem disabled={unique} value={1}>Show unique</MenuItem>
                                                        <MenuItem disabled={!unique} value={0}>Show all</MenuItem>
                                                    </CustomSelect>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Spread Factor" placement="top"><span>SF</span></Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Duty Cycled" placement="top"><span>DC</span></Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(messages.length > 0)
                                            ? messages.map((val, index) =>
                                                <TableRow
                                                    key={index}
                                                    className={val === selectedPacket ? "selected" : ""}
                                                    onClick={() => setSelectedPacket(val)}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <TableCell>{new Date(val.ts).toLocaleString()}</TableCell>
                                                    <TableCell>{val.messageType}</TableCell>
                                                    <TableCell>{val.messageType === "Uplink" ? val.fport : "-"}</TableCell>
                                                    <TableCell>{val.messageType === "Uplink" ? val.fcntUp : val.fcntDown}</TableCell>
                                                    <TableCell>{val.spreadFactor}</TableCell>
                                                    <TableCell>{val.dutyCycled ? "T" : "F"}</TableCell>
                                                </TableRow>
                                            )
                                            : <TableRow>
                                                <TableCell colSpan={6}>There is no messages to decode</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    {!isAllLoaded && <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                                                <Button disabled={loading} onClick={loadMore}>{loading ? 'Loading...' : 'Press to load more packets'}</Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>}
                                </CustomTable>
                            </TableContainer>
                        </CardContent>
                    </CustomCard>
                    <CustomCard sx={{ width: '46%' }}>
                        <CardMedia>
                            <Box className="d-flex" sx={{ alignItems: "center" }}>
                                <Tabs value={activeUpId} onChange={handleTabChange}>
                                    {uplinks.map(el => <Tab label={el.text} key={el.id} value={el.id} />)}
                                </Tabs>
                                <IconButton onClick={updateKeys} color="inherit">
                                    <Tooltip title="Refresh keys">
                                        <Box component="img" src={RefreshIcon} />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        </CardMedia>
                        <CardContent>
                            <CodeBox>
                                {activeUpId === 1
                                    ? appMessage || "No payload to decode."
                                    : activeUpId === 2
                                        ? networkMessage || "No payload to decode."
                                        : activeUpId === 3
                                            ? loraMessage || "No payload to decode."
                                            : <p>No payload to decode.</p>
                                }
                            </CodeBox>
                        </CardContent>
                    </CustomCard>
                </Box>
                : <Box className="d-flex-center" height="100%"><CircularProgress color="primary" /></Box>}
        </>)
});

export default Uplink;
