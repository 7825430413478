import lora from "lora-packet";

export function formatLoraMessage(message) {
    const lines = message.split('\n');
    const lengths = lines.map(s => s.replace(/^\s*(.*)( = .*)$/, (match, m1, m2) => m1).length);
    const max = Math.max(...lengths.filter(length => length > 0));
    const formattedMsg = lines.map(s => s.replace(/^\s*(.*?)( = .*)$/, (match, m1, m2) => ' '.repeat(max - m1.length) + m1 + m2)).join('\n');
    return formattedMsg;
}

export function handleLoraMessage(packet, nwkSKey, appSKey, fcntMSB32, assume) {
    try {
        let decoded = packet.toString();
        let mbs = fcntMSB32?.readUInt16LE(0);
        let hexMBS = mbs && `0x${('0000' + mbs.toString(16)).substr(-4)}`;
        if (appSKey) {
            const valid = assume ? lora.verifyMIC(packet, nwkSKey, appSKey) : lora.verifyMIC(packet, nwkSKey, appSKey, fcntMSB32);
            decoded = decoded.replace(/^(.*MIC = .*$)/m, '$1'
                + (valid
                    ? ' <span style="color: #00A300">VALID</span>'
                    : ` <span style="color: #f00">INVALID</span> ${assume ? fcntMSB32 ? '\n $1 ' + `(assuming 32 bits FCnt with MSB ${hexMBS})` : `(tried MSB 0x0000-0xFFFF)` : ""}`
                ));
        }
        if (fcntMSB32) {
            let newFcnt = mbs << 16 | packet.getFCnt();
            if (!assume) {
                decoded = decoded.replace(/^((.*FCnt = )(\S*)$)/m, '$2' + `${newFcnt}`)
            } else {
                decoded = decoded.replace(/^((.*FCnt = )(\S*)$)/m, '$1' + '\n$2' + `${newFcnt}` + ` (assuming 32 bits FCnt with MSB ${hexMBS})`)
            }
        }
        return decoded;
    }
    catch (error) {
        console.warn(error);
        throw error;
    }
}