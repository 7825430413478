import React, { memo, useState, useEffect, useContext } from 'react';
import NotificationContext from "../../context/NotificationContext";
import ParameterDescription from "./ParameterDescription";
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Box, MenuItem, Typography, Stepper, Step, StepLabel, Tooltip } from '@mui/material';
import { CustomButton, CustomSelect, CustomTable } from "../common/StyledComponents/";
import Edit from "../../images/edit-icon.png";
import { sendDownlink } from "../Worker/ns.js";
import { checkvalidHexOrBase64 } from "../../utils";
import merge from 'lodash.merge';

const SAVE_SETTINGS_CATEGORY = "cmd_ctrl";
const SAVE_SETTINGS_GROUP = "write_to_flash";
const SAVE_SETTINGS_VALUES = {
    app_configuration: 1,
    lora_configuration: 1,
    restart_sensor: 0
};
const DOWNLINKS_SAVE_SETTINGS = { [SAVE_SETTINGS_CATEGORY]: { [SAVE_SETTINGS_GROUP]: { write: SAVE_SETTINGS_VALUES } } };

function isSaveSettingExist(data) {
    return data.find(c => c.category === SAVE_SETTINGS_CATEGORY &&
        c.groups.find(y => y.group === SAVE_SETTINGS_GROUP &&
            Object.getOwnPropertyNames(SAVE_SETTINGS_VALUES).every(n => y.parameters.find(p => p.parameter_name === n))))
}

const DownlinkGeneratorSettings = memo(({
    sensorEncoder,
    deviceId,
    categories,
    data
}) => {
    const notificationCtx = useContext(NotificationContext);

    const [selectedCategory, setSelectedCategory] = useState("");
    const [modifiedCategories, setModifiedCategories] = useState([]);

    const [downlinksObject, setDownlinksObject] = useState({});
    const [encodedData, setEncodedData] = useState({});
    const [errorEncodedData, setErrorEncodedData] = useState(false);
    const [portList, setPortList] = useState([]);
    const [active, setActive] = useState();
    const [sentList, setSentList] = useState([]);

    const [openAll, setOpenAll] = useState(true);
    const [withSaveSetting, setWithSaveSetting] = useState(null);

    useEffect(() => {
        setDownlinksObject({});
        setSelectedCategory(categories[0].name);
        setWithSaveSetting(isSaveSettingExist(data));
    }, [sensorEncoder])

    useEffect(() => {
        setModifiedCategories(Object.keys(downlinksObject));
        setSentList([]);
    }, [downlinksObject])

    useEffect(() => {
        try {
            let encodeFile = require("../../sensorencoders" + sensorEncoder)
            let data = encodeFile.encodeDownlink(downlinksObject)
            let encodedData = {};
            for (let port in data) {
                let encodedObj = {}
                if (port === "error") {
                    notificationCtx.error("Something went wrong!");
                    setErrorEncodedData(true);
                    break;
                }
                encodedObj.port = port
                let hexstring = ""
                for (let item in data[port]) {
                    let h = parseInt(data[port][item]).toString(16).padStart(2, '0')
                    hexstring += h + " "
                }
                encodedObj.hex = hexstring
                encodedObj.base64 = checkvalidHexOrBase64(hexstring, true)
                encodedData[port] = encodedObj;
            }
            let ports = Object.keys(encodedData);
            if (!active || !ports.includes(active)) setActive(ports[0]);
            setPortList(ports);
            setEncodedData(encodedData);
        } catch (e) {
            console.log(e)
            notificationCtx.error(e.message);
        }
    }, [downlinksObject])

    async function sendGeneratedDownlink(encodedData) {
        try {
            let data = encodedData.base64.toString()
            let port = parseInt(encodedData.port)
            console.log("Sending downlink: " + data + " to port: " + port);
            if (data !== "") {
                let res = await sendDownlink(data, port, deviceId)
                if (res.status === 200) {
                    setSentList(oldSentList => {
                        let newSentList = [...oldSentList];
                        newSentList.push(port);
                        return newSentList
                    });
                    notificationCtx.success("Send successful!")
                } else {
                    notificationCtx.error("Send failed!")
                }
            }
        } catch (e) {
            notificationCtx.error(e.message)
        }
    }

    function clearDownlinkGenerator() {
        setDownlinksObject({});
        setOpenAll(true);
        setErrorEncodedData(false);
        notificationCtx.success("Clear all settings!")
    }

    function saveSettings() {
        setDownlinksObject((downlinksObject) => {
            let newDownlinksObject = { ...downlinksObject };
            newDownlinksObject = merge(newDownlinksObject, DOWNLINKS_SAVE_SETTINGS);
            return newDownlinksObject;
        });
        notificationCtx.success("Flash Write Command settings saved!")
    }

    const handleTableLineChange = (updatedCategoryObject) => {
        setDownlinksObject((oldObject) => {
            let newObject = { ...oldObject }
            if (Object.keys(updatedCategoryObject).length === 0) {
                delete newObject[selectedCategory]
            } else {
                newObject[selectedCategory] = updatedCategoryObject;
            }
            return newObject;
        });
    }

    const sendAllGeneretedDownlinks = () => {
        for (let port in encodedData) {
            sendGeneratedDownlink(encodedData[port]);
        }
    }

    const showEncodedDownlink = (encodedObject) => {
        return (
            <Table className='encoded-data'>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography variant="title3">Port</Typography>
                        </TableCell>
                        <TableCell>{encodedObject.port}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="title3">Hex</Typography>
                        </TableCell>
                        <TableCell>{encodedObject.hex}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="title3">Base64</Typography>
                        </TableCell>
                        <TableCell>{encodedObject.base64}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    return (
        <Box className='d-flex-column' height="100%">
            <Box width="100%" className="d-flex" alignItems="center" pb={2}>
                <Box sx={{ width: "85%" }}>
                    {portList.length > 1 &&
                        <Stepper alternativeLabel activeStep={portList.indexOf(active)}>
                            {portList.map((port) =>
                                <Step key={port} completed={sentList.includes(port)}>
                                    <StepLabel icon={parseInt(port)} onClick={() => setActive(port)} />
                                </Step>
                            )}
                        </Stepper>
                    }
                    <Box className="box code" width="100%" minHeight="100px" position="relative">
                        {!errorEncodedData
                            ? showEncodedDownlink(active ? encodedData[active] : {})
                            : <Typography variant="title3">Error!</Typography>}
                    </Box>
                </Box>
                {sendGeneratedDownlink &&
                    <Box sx={{ textAlign: "center", width: "15%" }}>
                        <CustomButton className="send-btn" variant="contained" disabled={errorEncodedData || portList.length === 0}
                            onClick={sendAllGeneretedDownlinks}>
                            {`Send ${(portList.length > 1) ? " (" + portList.length + ")" : ""}`}
                        </CustomButton>
                    </Box>}
            </Box>
            <Box className="d-flex" alignItems="start" >
                <CustomSelect
                    className='categories-select'
                    value={selectedCategory}
                    displayEmpty
                    onChange={e => { setSelectedCategory(e.target.value) }}
                    required
                >
                    {categories.map((option, index) =>
                        <MenuItem value={option.name} key={index}>
                            {modifiedCategories.includes(option.name) &&
                                <Box component="img" src={Edit} sx={{ width: "15px", marginLeft: "-15px" }} alt="edit" />}
                            {option.description}
                        </MenuItem>)}
                </CustomSelect>
                <Box className="d-flex" alignItems="start" gap="15px" >
                    {withSaveSetting &&
                        <Tooltip title="Set Flash Write Command: App and LoRa configurations are saved and the device will not restart" placement='top'>
                            <CustomButton variant="outlined" onClick={saveSettings}>Save Settings</CustomButton>
                        </Tooltip>}
                    <CustomButton variant="contained" onClick={clearDownlinkGenerator}>Clear all</CustomButton>
                </Box>
            </Box>
            <TableContainer sx={{ overflowY: "auto", flexGrow: 1 }}>
                <CustomTable stickyHeader className="parameters-table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={"10%"}>{"Enable"}</TableCell>
                            <TableCell width={"35%"}>{"Parameter"}</TableCell>
                            <TableCell width={"20%"}>{"Access(Read/Write)"}</TableCell>
                            <TableCell width={"35%"}>{"Value"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.find(obj => obj.category == selectedCategory)?.groups.map((group) => {
                            if (group.group === "") {
                                return (
                                    group.parameters.map((data, i) => {
                                        return <ParameterDescription
                                            groupData={[data]}
                                            key={selectedCategory + "_" + data["parameter_name"]}
                                            onChange={handleTableLineChange}
                                            valueObject={downlinksObject[selectedCategory]}
                                            openAll={openAll} setOpenAll={setOpenAll}
                                        />
                                    })
                                )
                            } else {
                                return <ParameterDescription
                                    groupData={group.parameters}
                                    key={selectedCategory + "_" + group.group}
                                    onChange={handleTableLineChange}
                                    valueObject={downlinksObject[selectedCategory]}
                                    openAll={openAll} setOpenAll={setOpenAll} />
                            }
                        })}
                    </TableBody>
                </CustomTable>
            </TableContainer>
        </Box>
    )
})

export default DownlinkGeneratorSettings;
