import React from "react";
import { styled } from "@mui/material/styles"
import { Button, Box } from '@mui/material';

const CopyBtn = styled(Button)
    ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textTransform: "none",
        padding: "5px 20px",
        borderRadius: "20px",
        backgroundColor: "#F4F6FA",
        color: "#42454E"
    });

const CodeBox = ({ children, note }) => {
    return (
        <Box sx={{ position: "relative", height: note ? "calc(100% - 30px)" : "100%" }}>
            <CopyBtn onClick={() => { navigator.clipboard.writeText(children) }}>Copy</CopyBtn>
            <Box className="box scroller" sx={{ overflowY: "auto", maxHeight: "100%" }}>
                <div className="code" dangerouslySetInnerHTML={{__html: children}}>
                </div>
            </Box>
            {note && <div className="code-box-note">** {note}</div>}
        </Box>
    )
}

export default CodeBox;